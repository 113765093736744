import { Routes, Route, Link } from "react-router-dom";

export default function Home() {
  return (
    <div>
      <p>
        Which list would you like to view?
      </p>

      <p>
        <a href="naughty/" alt="View the naughty list!">
          <img src="/images/Naughty.png" className="list-image" />
        </a>
         <b>OR</b> 
        <a href="nice/" alt="View the nice list!">
          <img src="/images/Nice.png" className="list-image" />
        </a>
      </p>

      <p>
        <Link to="parent/">Parents, click here to tell Santa Claus to add your children to the list!</Link>
      </p>

      <p>
        <iframe width="75%" height="300" src="https://www.youtube.com/embed/yzXDYDfRbRU?loop=1&controls=0showinfo=0&playlist=yzXDYDfRbRU" frameBorder="0" allowFullScreen></iframe>
        <span className="paragraph">Having trouble finding your name on my list? With so many kids in the world, the elves are having a hard time keeping the list up-to-date. Tell your parents to visit this website and Santa's Web Division will be right on it!</span>
        <span className="cursive-font">-Santa Claus</span>
      </p>
    </div>
  );
}
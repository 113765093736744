import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useForm } from "react-hook-form";

export default function Admin(props) {
  const { register, handleSubmit, setValue, setError, formState: { errors } } = useForm();

  /**
  * List of all users
  */
  const [users, setUsers] = useState([]);

  /**
  * List of all children
  */
  const [children, setChildren] = useState([]);

  /**
  * E-mail of user, before being changed by user
  */
  const [userEmail, setUserEmail] = useState("");

  /**
   * On page load
  */
  useEffect(() => {
    updateUsers();
    updateChildren();
  }, []);

  /**
  * Handles get users request
  */
  const updateUsers = () => {
    Axios.get("/allusers").then((response) => {
      // Set up select array
      let array = [];

      // Iterate through results
      for (let i = 0; i <= response.data.result.length - 1; i++) {
        array.push(
          <option key={i} value={response.data.result[i].id}>
            {response.data.result[i].email}
          </option>
        );
      }

      // Set select array
      setUsers([array]);
    });
  };

  /**
  * Handles get child request
  */
  const updateChildren = () => {
    Axios.get("/allchildrenadmin").then((response) => {
      // Set up select array
      let array = [];

      // Iterate through results
      for (let i = 0; i <= response.data.result.length - 1; i++) {
        array.push(
          <option key={i} value={response.data.result[i].id}>
            {response.data.result[i].name}
          </option>
        );
      }

      // Set select array
      setChildren([array]);
    });
  };

  const [formUserValue, setFormUserValue] = useState({
    id: -1
  });

  const [formChildValue, setFormChildValue] = useState({
    id: -1
  });

  const handleUserChange = (event) => {
    // Check if has value
    if (event.target.value <= 0) { setValue("email", ""); return; }

    setFormUserValue({
      id: -1,
    });

    /**
     * Handles get users request
    */
    Axios.get("/user", {
      params: {
        id: event.target.value,
      }
    }).then((response) => {
      setFormUserValue({
        id: response.data.result[0].id
      });

      setValue('email', response.data.result[0].email);
      setValue('credits', response.data.result[0].credits);

      setUserEmail(response.data.result[0].email);
    });
  };

  const userOnSubmit = (e) => {
    Axios.post("/edituser", {
      id: formUserValue.id,
      email: e.email,
      credits: e.credits,
    }).then((response) => {
      setFormUserValue({
        id: -1,
      });

      setUserEmail("");
      updateUsers();
    });
  }

  const userOnSubmitDelete = (e) => {
    Axios.post("/deleteuser", {
      id: formUserValue.id,
    }).then((response) => {
      setFormUserValue({
        id: -1,
      });

      setValue('email', '');
      setValue('credits', 0);

      setUserEmail("");
      updateUsers();
    });
  }

  const handleChildChange = (event) => {
    // Check if has value
    if (event.target.value <= 0) { setFormChildValue({ name: '' }); return; }

    // Need this here, otherwise when changing children, won't update selec†s
    setFormChildValue({
      id: -1,
    });

    /**
     * Handles get child request
    */
    Axios.get("/getchildadmin", {
      params: {
        childid: event.target.value,
      }
    }).then((response) => {
      var month = response.data.result[0].birthday.split('-')[1];
      var day = response.data.result[0].birthday.split('-')[2].substr(0, 2);
      var year = response.data.result[0].birthday.split('-')[0];

      setFormChildValue({
        id: response.data.result[0].id,
        name: response.data.result[0].name,
        month: month,
        day: day,
        year: year,
        status: response.data.result[0].status,
        reason: response.data.result[0].reason,
      });

      setValue('name', response.data.result[0].name);
      setValue('month', month);
      setValue('day', day);
      setValue('year', year);
      setValue('status', response.data.result[0].status);
      setValue('reason', response.data.result[0].reason);
    });
  };

  const childOnSubmit = (e) => {
    Axios.post("/updatechildadmin", {
      childid: formChildValue.id,
      name: e.name,
      month: e.month,
      day: e.day,
      year: e.year,
      status: e.status,
      reason: e.reason,
    }).then((response) => {
      setFormChildValue({
        id: -1
      });

      setValue('name', '');
      setValue('month', 1);
      setValue('day', 1);
      setValue('year', 2022);
      setValue('status', 0);
      setValue('reason', '');

      updateChildren();
    });
  }

  const checkEmailExist = (e) => {
    Axios.get("/checkemail", {
      params: {
        email: formUserValue.email,
      }
    }).then((response) => {
      if (response.data.result && userEmail != formUserValue.email) {
        setError('email', { type: 'custom', message: 'E-mail address already taken!' });
      } else {
        userOnSubmit(e);
      }
    });
  }

  const checkUserForm = (e) => {
    checkEmailExist(e);
  }

  /**
   * Array of years to be used for childens birthdays
  */
  const years = [];

  // Creates years for child birthdays
  for (let i = new Date().getFullYear(); i >= 1900; i--) {
    years.push(<option value={i} key={i}>{i}</option>);
  }

  if (props.loginStatus) {
    return (
      <div>
        <h1>Admin Panel</h1>

        <form method="post" onSubmit={handleSubmit(checkUserForm)}>
          <div className="input-box">
            <b>Edit User:</b>
            <br />
            <select name="editUser" onChange={handleUserChange} value={formUserValue.id}>
              <option key="-1" value="-1">Please select a user...</option>
              {users}
            </select>

            {formUserValue.id > -1 ? (
              <span>
                <div className="input-box">
                  Email:<br />
                  <input
                    type="text"
                    {...register("email", {
                      required: "Please enter an e-mail address.",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Enter a valid e-mail address."
                      }
                    })}
                    value={formUserValue.email} />

                  {errors.email && <span role="form-error">{errors.email.message}</span>}
                </div>

                <div className="input-box">
                  Credits:<br />
                  <input
                    type="number"
                    {...register("credits", {
                      required: "Please enter a number of credits.",
                    })}
                    value={formUserValue.credits} />

                  {errors.credits && <span role="form-error">{errors.credits.message}</span>}
                </div>

                <div className="input-box">
                  <button onClick={userOnSubmitDelete}>Delete</button>
                  <button type="submit">Save</button>
                </div>
              </span>
            ) : ''}
          </div>
        </form>

        <form method="post" onSubmit={handleSubmit(childOnSubmit)}>
          <b>Edit Child:</b>
          <br />
          <div className="input-box">
            <select name="editChild" onChange={handleChildChange} value={formChildValue.id}>
              <option key="-1" value="-1">Please select a child...</option>
              {children}
            </select>
          </div>

          {formChildValue.id > -1 ? (
            <span>
              <div className="input-box">
                <b>Child name:</b>
                <br />
                <small><i>This will be the name displayed on the site.</i></small>
                <br />
                <input
                  type="text"
                  {...register("name", {
                    required: "Please enter your child's name.",
                  })}
                  maxLength="50" />

                {errors.name && <span role="form-error">{errors.name.message}</span>}
              </div>

              <div className="input-box">
                <b>Birthday</b>
                <br />
                <select
                  {...register("month")}>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>

                <select
                  {...register("day")}>
                  <option value="01">1</option>
                  <option value="02">2</option>
                  <option value="03">3</option>
                  <option value="04">4</option>
                  <option value="05">5</option>
                  <option value="06">6</option>
                  <option value="07">7</option>
                  <option value="08">8</option>
                  <option value="09">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                </select>

                <select name="year"
                  {...register("year")}>
                  {years}
                </select>
              </div>

              <div className="input-box">
                <b>Naughty or Nice?</b>
                <br />
                <select
                  {...register("status")}>
                  <option value="0">Naughty</option>
                  <option value="1">Nice</option>
                </select>
              </div>

              <div className="input-box">
                <b>Reason:</b>
                <br />
                <small><i>What is their reason for being naughty or nice?</i></small>
                <br />
                <textarea
                  rows="5"
                  {...register("reason", {
                    required: "Please enter a reason for why your child is naughty or nice.",
                  })}
                  defaultValue={formChildValue.reason}>
                </textarea>

                {errors.reason && <span role="form-error">{errors.reason.message}</span>}
              </div>

              <div className="input-box">
                <button type="submit">Save</button>
              </div>
            </span>
          ) : ''}
        </form>
      </div>
    )
  } else {
    return (
      <div>
        <p className="center-content">
          You are unable to view this page.
        </p>
      </div>
    )
  }
}
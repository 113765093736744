import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Axios from "axios";

export default function ChangePass() {
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();

    /**
     * When password is changed, show message
     */
    const [passwordChanged, setPasswordChanged] = useState(false);

    /**
     * Handles submission
    */
    const submitChange = (e) => {
        Axios.post("/changepass", {
            oldpassword: e.oldpassword,
            newpassword: e.newpassword,
        }).then((response) => {
            setValue("oldpassword", "");
            setValue("newpassword", "");
            setPasswordChanged(true);
        });
    }

    if (!passwordChanged) {
        return (
            <div>
                <h1>Change Password</h1>

                <form method="post" onSubmit={handleSubmit(submitChange)}>
                    <div className="input-box">
                        <b>Old Password:</b>
                        <br />
                        <input {...register("oldpassword", {
                            required: "Please enter old password.",
                        })}
                            type="password"
                            maxLength="32" />

                        {errors.oldpassword && <span role="form-error">{errors.oldpassword.message}</span>}
                    </div>

                    <div className="input-box">
                        <b>New Password:</b>
                        <br />
                        <input {...register("newpassword", {
                            required: "Please enter a new password.",
                            minLength: {
                                value: 6,
                                message: "A password must be specified and be at least six characters."
                            },
                        })}
                            type="password"
                            maxLength="32" />

                        {errors.newpassword && <span role="form-error">{errors.newpassword.message}</span>}
                    </div>

                    <div className="input-box">
                        <b>New Password (Confirm):</b>
                        <br />
                        <input {...register("newpasswordC", {
                            required: "Please confirm your new password.",
                            validate: (val: string) => {
                                if (watch('newpassword') != val) {
                                    return "Your passwords do not match.";
                                }
                            }
                        })}
                            type="password"
                            maxLength="32" />

                        {errors.newpasswordC && <span role="form-error">{errors.newpasswordC.message}</span>}
                    </div>

                    <div className="input-box">
                        <button type="submit">Change Password</button>
                    </div>
                </form>
            </div>
        );
    } else {
        return (
            <div>
                <p>Password Changed!</p>
            </div>
        );
    }
}
import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";
import Home from "./Pages/Home";
import Parent from "./Pages/Parent";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Admin from "./Pages/Admin";
import List from "./Pages/List";
import Forgot from "./Pages/Forgot";
import ChangePass from "./Pages/ChangePass";

function App() {
  /**
   * Checks to see if the user is logged in
   */
  const [loginStatus, setLoginStatus] = useState(false);

  /**
   * Checks to see if the user is logged in
   */
  const [adminStatus, setAdminStatus] = useState(false);

  /**
   * Checks to see if the user attempted to log in
   */
  const [logAttempt, setLogAttempt] = useState(true);

  /**
   * The number of credits owned by user
   */
  const [credits, setCredits] = useState(0);

  /**
   * User ID on login
   */
  const [userID, setUserID] = useState(0);

  /**
   * Page title
  */
  const [pageTitle, setPageTitle] = useState("Santa's Naughty and Nice List");

  /**
   * Set up objects
   */
  Axios.defaults.withCredentials = true;
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Handles authentication - Checks to see if user is logged in
   */
  const userAuthenticated = () => {
    Axios.get("/isUserAuth")
      .then((response) => {
        if (response.data.loggedIn) {
          // Check if admin
          if (response.data.admin == 1) {
            setAdminStatus(true);
          }
          setLoginStatus(true);
          setLogAttempt(true);
          setUserID(response.data.id);
        } else {
          setLoginStatus(false);
          setAdminStatus(false);
        }
      });
  }

  /**
   * Logs the user out
   */
  const logout = () => {
    Axios.post("/logout")
      .then((response) => {
        setLoginStatus(false);
        setLogAttempt(true);
        navigate("/");
      });
  }

  /**
   * Handles get credit request
  */
  const getCredits = (d) => {
    Axios.get("/credits")
      .then((response) => {
        setCredits(response.data.result[0].credits);
      });
  }

  /**
   * On page load
  */
  useEffect(() => {
    // Keep user session active
    userAuthenticated();

    // Load credits
    getCredits();
  }, []);

  /**
   * On page change
  */
  useEffect(() => {
    // Check if page title needs to be reset
    if (location.pathname != "/nice/" && location.pathname != "/naughty/") {
      setPageTitle("Santa\'s Naughy and Nice List");
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="App">
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <div className="main-image">
                <img src="/images/top-image.png" className="img-fluid" />
                <div className="main-text">
                  <h1>
                    <Link to="/">{pageTitle}</Link>
                  </h1>
                  <div className="top-flower"><img src="/images/top-flower.png" className="img-fluid" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="content-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="middle-content">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/parent" element={<Parent loginStatus={loginStatus} userID={userID} credits={credits} setCredits={setCredits} getCredits={getCredits} />} />
                  <Route path="/login" element={<Login navigate={() => navigate("/parent") } loginStatus={loginStatus} logAttempt={logAttempt} setLogAttempt={setLogAttempt} setLoginStatus={setLoginStatus} setUserID={setUserID} setAdminStatus={setAdminStatus} />} />
                  <Route path="/register" element={<Register loginStatus={loginStatus} />} />
                  <Route path="/admin" element={<Admin loginStatus={loginStatus} />} />
                  <Route path="/naughty" element={<List status="0" pageTitle={pageTitle} setPageTitle={setPageTitle} />} />
                  <Route path="/nice" element={<List status="1" pageTitle={pageTitle} setPageTitle={setPageTitle} />} />
                  <Route path="/forgot" element={<Forgot loginStatus={loginStatus} />} />
                  <Route path="/changepass" element={<ChangePass loginStatus={loginStatus} />} />
                </Routes>

                {loginStatus && location.pathname != "/" && location.pathname != "/nice/" && location.pathname != "/naughty/" && (
                  <div>
                    <hr />
                    <ul className="bottom-list">
                      {location.pathname != "/parent/" && location.pathname != "/parent" ? <li><Link to="parent/">Parent Home</Link></li> : ''}
                      {location.pathname != "/admin/" && adminStatus ? <li><Link to="admin/">Admin Panel</Link></li> : ''}
                      {location.pathname != "/changepass/" ? <li><Link to="changepass/">Change Password</Link></li> : ''}
                      <li><Link to="#/" onClick={logout}>Logout</Link></li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <div className="main-image">
                <img src="/images/bottom-image.png" className="img-fluid" />
                <div className="main-text">
                  <div className="bottom-flower"><img src="/images/bottom-flower.png" className="img-fluid" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-adverts">
          <Link to="/parent">
            <img src="/images/ParentB.png" border="0" alt="Parent's click here!" />
          </Link>

          <a href="https://www.santashub.com">
            <img src="/images/santashubcom_button.png" border="0" alt="Visit SantasHub.com" />
          </a>

          <a href="https://www.santaswishlistmaker.com">
            <img src="/images/santaswishcom_button.png" border="0" alt="Visit SantasWishlistMaker.com" />
          </a>

          <a href="https://easterlist.com">
            <img src="/images/easterbunny_button.png" border="0" alt="Visit EasterList.com" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
